import React, { useCallback } from 'react';
import {
	convertCustomOrLocaleKeyToLabel,
	useCustomAndSearchableTextSelectionOptions,
} from '@common/hooks/useCustomAndSearchableTextSelectionOptions';
import { ShareFileSpinner } from '@common/icons';
import { GenerationFormParams } from '@sdk';
import { t } from '@utils/localization';
import {
	Checkbox,
	DatePicker,
	Flex,
	Form,
	FormInstance,
	Input,
	Select,
	theme,
} from 'antd';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import { employmentIndustries } from './employmentIndustries';
import { employmentRoles } from './employmentRoles';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(localizedFormat);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

interface GenerationFormProps {
	form: FormInstance<GenerationFormParams<'sharefile:requestlist'>>;
	initialValues: GenerationFormParams<'sharefile:requestlist'>;
	disabled: boolean;
	initialFormData: GenerationFormParams<'sharefile:requestlist'>;
}

const GenerationForm: React.FC<GenerationFormProps> = props => {
	const { token } = theme.useToken();
	const { form } = props;
	const employmentIndustriesSelection = useCustomAndSearchableTextSelectionOptions({
		initialOptions: employmentIndustries,
		initialSelected: props.initialFormData.employment_industry_locale_key_or_custom,
	});
	const employmentRolesSelection = useCustomAndSearchableTextSelectionOptions({
		initialOptions: employmentRoles(
			props.initialFormData.employment_industry_locale_key_or_custom
		),
		initialSelected: props.initialFormData.employment_role_locale_key_or_custom,
	});

	const setSelectedIndustry = useCallback(
		(industryKey: string) => {
			// Set the industry value
			employmentIndustriesSelection.setSelected(industryKey);

			// Based on the selected industry, update the employment role options and select the first one.
			// If the currently selected employment role is a custom value, add it to the top of the list
			// of options and select it.
			const newEmploymentRoleOptions = employmentRoles(industryKey);
			if (employmentRolesSelection.selected.startsWith('custom::')) {
				newEmploymentRoleOptions.unshift({
					label: convertCustomOrLocaleKeyToLabel(employmentRolesSelection.selected),
					value: employmentRolesSelection.selected,
				});
			}
			employmentRolesSelection.setOptions(newEmploymentRoleOptions);
			employmentRolesSelection.setSelected(newEmploymentRoleOptions[0].value);
			employmentRolesSelection.setSearchValue('');
			form.setFieldsValue({
				employment_role_locale_key_or_custom: newEmploymentRoleOptions[0].value,
			});
		},
		[employmentIndustriesSelection, employmentRolesSelection, form]
	);

	return (
		<Form
			form={form}
			disabled={props.disabled}
			layout="vertical"
			initialValues={props.initialValues}
			data-testid="request-list-generation-form"
		>
			<Flex vertical gap={token.marginMD}>
				<Flex gap={token.size} align="center" justify="space-between">
					<Form.Item<GenerationFormParams<'sharefile:requestlist'>>
						label={t(
							'use_resourcegen_drawer.sharefile_requestlist.generation_form.request_name'
						)}
						name="request_name"
						required
						style={{ flexGrow: 1, width: '100%' }}
						rules={[{ required: true }]}
					>
						<Input />
					</Form.Item>
					<Form.Item<GenerationFormParams<'sharefile:requestlist'>>
						label={t(
							'use_resourcegen_drawer.sharefile_requestlist.generation_form.due_date'
						)}
						name={'due_date'}
						style={{ flexGrow: 1, width: '100%' }}
					>
						<DatePicker
							format={{
								format: dayjs.localeData().longDateFormat('L'),
								type: 'mask',
							}}
							minDate={dayjs()}
							style={{ width: '100%' }}
							size="middle"
						/>
					</Form.Item>
				</Flex>

				<Flex vertical gap={0}>
					<Flex gap={token.size} align="flex-end" justify="space-between">
						<Form.Item<GenerationFormParams<'sharefile:requestlist'>>
							label={t(
								'use_resourcegen_drawer.sharefile_requestlist.generation_form.employment_industry'
							)}
							name="employment_industry_locale_key_or_custom"
							style={{
								width: '100%',
								marginBottom: 0,
							}}
						>
							<Select
								showSearch
								placeholder={t('common.type_or_select_one')}
								value={employmentIndustriesSelection.selected}
								style={{ width: '100%' }}
								searchValue={employmentIndustriesSelection.searchValue}
								defaultActiveFirstOption
								labelRender={labelProps => {
									return (
										employmentIndustries.find(x => x.value === labelProps.value) ?? {
											label: convertCustomOrLocaleKeyToLabel(labelProps.value as string),
											value: labelProps.value,
										}
									).label;
								}}
								onSearch={employmentIndustriesSelection.handleSearch}
								onChange={setSelectedIndustry}
								loading={employmentIndustriesSelection.options.length === 0}
								notFoundContent={<ShareFileSpinner />}
								options={employmentIndustriesSelection.options || []}
							/>
						</Form.Item>
						<Form.Item<GenerationFormParams<'sharefile:requestlist'>>
							label={t(
								'use_resourcegen_drawer.sharefile_requestlist.generation_form.employment_role'
							)}
							name="employment_role_locale_key_or_custom"
							style={{
								width: '100%',
								marginBottom: 0,
							}}
						>
							<Select
								placeholder={t('common.type_or_select_one')}
								showSearch
								searchValue={employmentRolesSelection.searchValue}
								value={employmentRolesSelection.selected}
								labelRender={labelProps => {
									return (
										employmentRoles().find(x => x.value === labelProps.value) ?? {
											label: convertCustomOrLocaleKeyToLabel(labelProps.value as string),
											value: labelProps.value,
										}
									).label;
								}}
								style={{ width: '100%' }}
								defaultActiveFirstOption
								onSearch={employmentRolesSelection.handleSearch}
								onChange={employmentRolesSelection.setSelected}
								loading={employmentRolesSelection.options.length === 0}
								notFoundContent={<ShareFileSpinner />}
								options={employmentRolesSelection.options}
							/>
						</Form.Item>
					</Flex>
					<Form.Item<GenerationFormParams<'sharefile:requestlist'>>
						name="remember_industry_role"
						valuePropName="checked"
						style={{ marginBottom: 0, paddingTop: token.paddingXS }}
					>
						<Checkbox>
							{t(
								'use_resourcegen_drawer.sharefile_requestlist.generation_form.remember_industry_role'
							)}
						</Checkbox>
					</Form.Item>
				</Flex>

				<Form.Item<GenerationFormParams<'sharefile:requestlist'>>
					label={t(
						'use_resourcegen_drawer.sharefile_requestlist.generation_form.request_purpose'
					)}
					name="request_purpose"
					required
					rules={[{ required: true }]}
				>
					<Input.TextArea
						rows={6}
						placeholder={t(
							'use_resourcegen_drawer.sharefile_requestlist.generation_form.request_purpose_placeholder'
						)}
					/>
				</Form.Item>
			</Flex>
		</Form>
	);
};

export default GenerationForm;
