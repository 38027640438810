import { Api } from '@citrite/sf-api';
import { AsyncDevelopmentPage, RenderConditionally } from '@components';
import { configurePackagingSdk } from '@data/clients/packaging';
import { configureResourceGenerationSdk } from '@data/clients/resourcegen';
import { setLogger } from '@logger';
import { getDefaultDrawerProps } from '@overlays/getDefaultOverlayOptions';
import { resourceGenPath } from '@routes';
import { resourceGenPiletOverlays } from '@sdk';
import { AiSparkles } from '@sharefiledev/icons';
import { PiletApi } from '@sharefiledev/sharefile-appshell';
import { t, withDefaultProviders } from '@utils';
import { RolesAndRequirements } from '@utils/types';
import { UseResourceGenerationOverlay } from './overlays';

const getRolesRequirements = () => {
	const requirements: RolesAndRequirements = {
		requiredRoles: ['Employee'],
	};

	return requirements;
};

export function setup(piletApi: PiletApi) {
	setLogger(piletApi.sf.getLogger());
	configureApiClients(piletApi);
	registerPages(piletApi);
	registerOverlays(piletApi);

	// Add the development tools only for the approved subdomains
	if (
		window.location.origin.endsWith('sharefiletest.com') &&
		isApprovedSubdomain(window.location.hostname)
	) {
		registerDeveloperTools(piletApi);
	}
}

function isApprovedSubdomain(hostname: string) {
	const host = hostname.split('.');

	if (host.length > 0) {
		const subdomain = host[0];
		const allowedSubdomains = ['aiteam', 'employeeshares', 'aiteam2'];

		return allowedSubdomains.includes(subdomain);
	}

	return false;
}

function configureApiClients(piletApi: PiletApi) {
	configurePackagingSdk(piletApi);
	configureResourceGenerationSdk(piletApi);
	piletApi.sf.registerSfApiConfigHandler(Api.configure as any);
}

function registerPages(piletApi: PiletApi) {
	piletApi.registerPage(
		resourceGenPath,
		withDefaultProviders(piletApi, props => (
			<RenderConditionally
				{...props}
				piletApi={piletApi}
				requirements={getRolesRequirements()}
				redirectToDashboard={false}
			>
				<div />
			</RenderConditionally>
		))
	);
}

function registerDeveloperTools(piletApi: PiletApi) {
	piletApi.sf.registerLeftNavComponent({
		href: '/resourcegen-development',
		title: () => 'Resource Gen Dev',
		icon: AiSparkles,
		weight: 50,
	});

	piletApi.registerPage(
		'/resourcegen-development',
		withDefaultProviders(piletApi, props => <AsyncDevelopmentPage piletApi={piletApi} />)
	);
}

function registerOverlays(piletApi: PiletApi) {
	piletApi.registerDrawer(
		resourceGenPiletOverlays.useResourceGen,
		withDefaultProviders(piletApi, props => <UseResourceGenerationOverlay {...props} />),
		getDefaultDrawerProps({
			titleText: t('use_resourcegen_drawer.sharefile_requestlist.title'),
			width: '43%',
		})
	);
}
